import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";
import { nord } from "react-syntax-highlighter/dist/esm/styles/prism";

import { IconClipboard, IconClipboardCheck } from "@tabler/icons";

const Code = ({ children, language }) => {
  const [isCopied, setIsCopied] = useState(false);

  SyntaxHighlighter.registerLanguage("jsx", jsx);

  const setCopied = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <div className="code">
      <div className="code__icons">
        <CopyToClipboard text={children}>
          <button onClick={() => setCopied()}>
            {isCopied ? (
              <span title="Copied!">
                <IconClipboardCheck />
              </span>
            ) : (
              <span title="Copy to Clipboard">
                <IconClipboard />
              </span>
            )}
          </button>
        </CopyToClipboard>
      </div>

      <SyntaxHighlighter language={language} style={nord}>
        {children}
      </SyntaxHighlighter>
    </div>
  );
};

export default Code;
