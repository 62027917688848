import { useState, useEffect } from "react";
import MarkDown from "markdown-to-jsx";
import Code from "./components/Code";

function App() {
  const [readable, setReadable] = useState("");
  useEffect(() => {
    import("./markdown/app.md").then((res) =>
      fetch(res.default)
        .then((response) => response.text())
        .then((response) => setReadable(response))
        .catch((err) => console.log(err))
    );
  }, []);

  return (
    <MarkDown
      options={{
        overrides: {
          Code: {
            component: Code,
          },
        },
      }}
      style={{
        maxWidth: "900px",
        margin: "0rem auto",
        padding: "0rem 2rem 2rem 2rem",
      }}
    >
      {readable}
    </MarkDown>
  );
}

export default App;
